<template>
  <v-row justify="center">

    <v-dialog v-model="show" persistent>
      <v-card color="gray" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="headline">
              La référence produit se trouve sur le côté de l'appareil
            </v-card-title>

            <v-card-subtitle >
              Trouvez cette étiquette sur l'apppareil
            </v-card-subtitle>


            <v-card-actions>
              <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  @click.stop="$emit('close')"
                  small>
                OK
              </v-btn>
            </v-card-actions>
          </div>

          <base-img
              class="ma-6"
              :src="require('@/assets/sticker.png')"
              contain
              max-height="280"
              max-width="150px"
          ></base-img>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ReferenceFinder",

  components: {},
  mounted() {},
  computed: {},
  props: {
    show: Boolean
  },
  data: () => ({}),
  methods: {}
};
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
